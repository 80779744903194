.contactme{
    padding: 61px 0px 61px 0px;
    background-color: rgb(245, 245, 245);
    min-height: 700px;
    height: 700px;
text-align: center;
}

.contactme-header{
    color: #C1B283;
    font-size: 30px;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 0px 0px;
}

/* .contactme-header-container{
margin-left: 15.666667%;
} */

/* -------------------------------------------------------------------------------------------------------------------------- */

/* FORM STYLES */

:root {
    --white: #afafaf;
    --red: #e31b23;
    --bodyColor: #292a2b;
    --borderFormEls: hsl(0, 0%, 10%);
    /* --bgFormEls: hsl(0, 0%, 14%); */
    --bgFormEls: #383838;
    --bgFormElsFocus: hsl(0, 7%, 20%);
}

/* .formcontainer {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
} */

.my-form h1 {
    margin-bottom: 1.5rem;
}

.my-form li,
.my-form .grid>*:not(:last-child) {
    margin-bottom: 1.5rem;
}

.my-form select,
.my-form input,
.my-form textarea,
.my-form button {
    width: 100%;
    line-height: 1.5;
    padding: 15px 10px;
    border: 1px solid var(--borderFormEls);
    color: var(--white);
    background: var(--bgFormEls);
    transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25), transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form textarea {
    height: 170px;
}

.my-form ::placeholder {
    color: inherit;
    /*Fix opacity issue on Firefox*/
    opacity: 1;
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus,
.my-form button:enabled:hover,
.my-form button:focus,
.my-form input[type="checkbox"]:focus+label {
    background: var(--bgFormElsFocus);
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus {
    transform: scale(1.02);
}

.my-form *:required,
.my-form select {
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 15px 15px;
}

.my-form *:required {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg);
}

.my-form select {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/down.svg);
}

.my-form *:disabled {
    cursor: default;
    filter: blur(2px);
}
/* FORM BTNS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.my-form .required-msg {
    display: none;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg) no-repeat center left / 15px 15px;
    padding-left: 20px;
}

.my-form .btn-grid {
    position: relative;
    overflow: hidden;
    transition: filter 0.2s;
}

.my-form button {
    font-weight: bold;
}

.my-form button>* {
    display: inline-block;
    width: 100%;
    transition: transform 0.4s ease-in-out;
}

.my-form button .back {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-110%, -50%);
}

.my-form button:enabled:hover .back,
.my-form button:focus .back {
    transform: translate(-50%, -50%);
}

.my-form button:enabled:hover .front,
.my-form button:focus .front {
    transform: translateX(110%);
}
/* CUSTOM CHECKBOX
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.my-form input[type="checkbox"] {
    position: absolute;
    left: -9999px;
}

.my-form input[type="checkbox"]+label {
    position: relative;
    display: inline-block;
    padding-left: 2rem;
    transition: background 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form input[type="checkbox"]+label::before,
.my-form input[type="checkbox"]+label::after {
    content: '';
    position: absolute;
}

.my-form input[type="checkbox"]+label::before {
    left: 0;
    top: 6px;
    width: 18px;
    height: 18px;
    border: 2px solid var(--white);
}

.my-form input[type="checkbox"]:checked+label::before {
    background: var(--red);
}

.my-form input[type="checkbox"]:checked+label::after {
    left: 7px;
    top: 7px;
    width: 6px;
    height: 14px;
    border-bottom: 2px solid var(--white);
    border-right: 2px solid var(--white);
    transform: rotate(45deg);
}