.introduction{
    text-align: center;
    padding: 61px 0px 61px 0px;
    padding-bottom: 100px;
    background-color: #383838;
    min-height: 400px;
    height: 400px;
}

.avatar { display: flex;
     align-items: center;
      justify-content: center 
    }

.introduction-text{
    padding: 10px 10px 30px 10px;
    margin: 10px 0px 30px 0px;

}

.about-me-header{
    color: #C1B283;
    font-size: 30px;
}

.about-me-text{
    color: #e7e2d1;
    font-size: 16px;
    /* font-weight: 100; */
    text-align: justify;
}

.job-title{
    color: #C1B283;
    font-weight: bolder;
}

@media only screen and (max-width: 1022px)  {
    .introduction{

        min-height: 800px;
        height: 800px;
    }
    
}