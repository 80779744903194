.Pagenotfound{
    background-image: url('../images/404.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    min-height: 100vh;
    text-align: center;
}

.notfound{
background-color: #041521;
height: 100%;
min-height: 100vh;
text-align: center;
}